import { FilterDefinition, ICompareExpression, ScopeDto, zCompareExpression, zFilterDefinition } from "./api";
import { EffectFilterCurrencyField, FieldTypes, MeasureFieldNames, Operators } from "./constants";
import { EffectType } from "./constants/EffectTypes";
import { EstimatesValue, IdeaStatus } from "./constants/IdeaConstants";
import { MeasureStatus } from "./constants/MeasureConstants";
import { MaxYear, MinYear } from "./constants/ValidationConstants";
import { capitalize } from "./helper/formatter";

export const EQUALITY_OPERATORS = [Operators.Equals, Operators.NotEquals, Operators.NotSet];
export const SINGLE_FIELD_OPERATORS = [Operators.In, Operators.NotIn, Operators.NotSet];
export const SET_OPERATORS = [Operators.In, Operators.NotIn, Operators.Equals, Operators.NotEquals, Operators.NotSet];
export const NUMERICAL_OPERATORS = [
    Operators.GreaterThan,
    Operators.SmallerThan,
    Operators.In,
    Operators.NotIn,
    Operators.Equals,
    Operators.NotEquals,
];

export const TIME_ESTIMATE_OPERATORS = [
    Operators.GreaterThan,
    Operators.SmallerThan,
    Operators.Equals,
    Operators.NotEquals,
    Operators.NotSet,
];
export const RELATIVE_DATE_OPERATORS = [Operators.Before, Operators.Within, Operators.InNext, Operators.InMore];
export const DATE_OPERATORS = [
    Operators.Before,
    Operators.Within,
    Operators.InNext,
    Operators.InMore,
    Operators.GreaterThan,
    Operators.SmallerThan,
    Operators.Equals,
    Operators.NotEquals,
    Operators.NotSet,
];
export const CURRENCY_OPERATORS = [Operators.GreaterThan, Operators.SmallerThan, Operators.Equals, Operators.NotEquals, Operators.NotSet];

export const OPERATORS_FOR_TYPE: Record<FieldTypes, Operators[]> = {
    [FieldTypes.Number]: NUMERICAL_OPERATORS,
    [FieldTypes.Text]: [Operators.Like],
    [FieldTypes.Single]: SINGLE_FIELD_OPERATORS,
    [FieldTypes.Currency]: CURRENCY_OPERATORS,
    [FieldTypes.Date]: DATE_OPERATORS,
    [FieldTypes.Set]: SET_OPERATORS,
    [FieldTypes.User]: SINGLE_FIELD_OPERATORS,
    [FieldTypes.Users]: SET_OPERATORS,
    [FieldTypes.Boolean]: EQUALITY_OPERATORS,
    [FieldTypes.TimeEstimate]: TIME_ESTIMATE_OPERATORS,
    [FieldTypes.Pulse]: [], // No filtering possible
};

// Use case: Ignore default operators for a specific field (e.g. NOT_SET for a SINGLE field that is always set)
export type IgnoreOperatorsForField = {
    [field: string]: Operators[];
};

export enum IdeaFieldNames {
    DisplayId = "displayId",
    Title = "title",
    CreatorId = "createdById",
    OwnerId = "ownerId",
    PotentialEstimate = "potentialEstimate",
    TimeEstimate = "timeEstimate",
    EffortEstimate = "effortEstimate",
    CreatedAt = "createdAt",
    LastModificationAt = "lastModificationAt",
    Description = "description",
    ReactionsCount = "reactionsCount",
    DiscardReason = "discardReason",
    DiscardStatement = "discardStatement",
    Status = "status",
}

export type FieldDefinition = {
    name: string;
    type: FieldTypes;
    attributeName?: string;
    values?: (string | number)[];
    isSortable?: boolean;
    isTimezoneAware?: boolean;
};

export const MEASURE_FIELDS = {
    [MeasureFieldNames.ClientIid]: {
        name: MeasureFieldNames.ClientIid,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.Title]: {
        name: MeasureFieldNames.Title,
        type: FieldTypes.Text,
        isSortable: true,
    },
    [MeasureFieldNames.CurrentGateTaskConfigId]: {
        name: MeasureFieldNames.CurrentGateTaskConfigId,
        type: FieldTypes.Single,
        isSortable: true,
    },
    [MeasureFieldNames.MeasureConfigId]: {
        name: MeasureFieldNames.MeasureConfigId,
        type: FieldTypes.Single,
        isSortable: true,
    },
    [MeasureFieldNames.MethodSegment]: {
        name: MeasureFieldNames.MethodSegment,
        type: FieldTypes.Single,
        attributeName: MeasureFieldNames.MethodSegment,
        values: [1, 2, 3],
        isSortable: true,
    },
    [MeasureFieldNames.Status]: {
        name: MeasureFieldNames.Status,
        type: FieldTypes.Single,
        values: [MeasureStatus.STATUS_DISCARDED, MeasureStatus.STATUS_OPEN, MeasureStatus.STATUS_CLOSED],
        isSortable: true,
    },
    [MeasureFieldNames.Currencies]: {
        name: MeasureFieldNames.Currencies,
        type: FieldTypes.Set,
        isSortable: false,
    },
    [MeasureFieldNames.ValueLever]: {
        name: MeasureFieldNames.ValueLever,
        type: FieldTypes.Single,
        attributeName: "valueLever",
        isSortable: true,
    },
    [MeasureFieldNames.AssignedToId]: {
        name: MeasureFieldNames.AssignedToId,
        type: FieldTypes.User,
    },
    [MeasureFieldNames.AssignedEditors]: {
        name: MeasureFieldNames.AssignedEditors,
        type: FieldTypes.Users,
        attributeName: MeasureFieldNames.AssignedEditors, // Set to something so that getColumnAccessor works
    },
    [MeasureFieldNames.CreatedAt]: {
        name: MeasureFieldNames.CreatedAt,
        type: FieldTypes.Date,
        isSortable: true,
    },
    [MeasureFieldNames.LastModificationAt]: {
        name: MeasureFieldNames.LastModificationAt,
        type: FieldTypes.Date,
        isSortable: true,
    },
    [MeasureFieldNames.LastViewedAt]: {
        name: MeasureFieldNames.LastViewedAt,
        type: FieldTypes.Date,
        isSortable: true,
    },
    [MeasureFieldNames.MyLastViewedAt]: {
        name: MeasureFieldNames.MyLastViewedAt,
        type: FieldTypes.Date,
        isSortable: true,
    },
    [MeasureFieldNames.Documents]: {
        name: MeasureFieldNames.Documents,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.Subtasks]: {
        name: MeasureFieldNames.Subtasks,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.OpenSubtasks]: {
        name: MeasureFieldNames.OpenSubtasks,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.CompletionDate]: {
        name: MeasureFieldNames.CompletionDate,
        type: FieldTypes.Date,
        isSortable: true,
    },
    [MeasureFieldNames.MeasureViews]: {
        name: MeasureFieldNames.MeasureViews,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.DaysInCurrentLevel]: {
        name: MeasureFieldNames.DaysInCurrentLevel,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.EffectType]: {
        name: MeasureFieldNames.EffectType,
        type: FieldTypes.Set,
        values: [EffectType.Savings, EffectType.ChangeoverCosts],
        isSortable: false,
        attributeName: "", // Workaround to get the getColumnAccessor working
    },
    [MeasureFieldNames.CurrentLevelDuedate]: {
        name: MeasureFieldNames.CurrentLevelDuedate,
        type: FieldTypes.Date,
        isSortable: true,
        isTimezoneAware: false,
    },
    [MeasureFieldNames.CurrentLevelAssignedTo]: {
        name: MeasureFieldNames.CurrentLevelAssignedTo,
        type: FieldTypes.User,
    },
    [MeasureFieldNames.CurrentDecider]: {
        name: MeasureFieldNames.CurrentDecider,
        type: FieldTypes.User,
    },
    [MeasureFieldNames.OverdueSubtasks]: {
        name: MeasureFieldNames.OverdueSubtasks,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.Favorite]: {
        name: MeasureFieldNames.Favorite,
        type: FieldTypes.Boolean,
        isSortable: true,
    },
    [MeasureFieldNames.EstimatedEffort]: {
        name: MeasureFieldNames.EstimatedEffort,
        type: FieldTypes.TimeEstimate,
        isSortable: true,
    },
    [MeasureFieldNames.TrackedTime]: {
        name: MeasureFieldNames.TrackedTime,
        type: FieldTypes.TimeEstimate,
        isSortable: true,
    },
    [MeasureFieldNames.EstimatedEffortImplementation]: {
        name: MeasureFieldNames.EstimatedEffortImplementation,
        type: FieldTypes.TimeEstimate,
        isSortable: true,
    },
    [MeasureFieldNames.TrackedTimeImplementation]: {
        name: MeasureFieldNames.TrackedTimeImplementation,
        type: FieldTypes.TimeEstimate,
        isSortable: true,
    },
    [MeasureFieldNames.PlanEfficiency]: {
        name: MeasureFieldNames.PlanEfficiency,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.ForecastEfficiency]: {
        name: MeasureFieldNames.ForecastEfficiency,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.UnassignedSubtasks]: {
        name: MeasureFieldNames.UnassignedSubtasks,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.CommentLast]: {
        name: MeasureFieldNames.CommentLast,
        type: FieldTypes.Date,
        isSortable: true,
        isTimezoneAware: false,
    },
    [MeasureFieldNames.CommentsCount]: {
        name: MeasureFieldNames.CommentsCount,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [MeasureFieldNames.GroupsWithAccess]: {
        name: MeasureFieldNames.GroupsWithAccess,
        type: FieldTypes.Set,
        isSortable: false,
    },
} satisfies Partial<Record<string, FieldDefinition>>;

export const IDEA_FIELDS = {
    [IdeaFieldNames.DisplayId]: {
        name: IdeaFieldNames.DisplayId,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [IdeaFieldNames.Title]: {
        name: IdeaFieldNames.Title,
        type: FieldTypes.Text,
        isSortable: true,
    },
    [IdeaFieldNames.Description]: {
        name: IdeaFieldNames.Description,
        type: FieldTypes.Text,
        isSortable: true,
    },
    [IdeaFieldNames.CreatorId]: {
        name: IdeaFieldNames.CreatorId,
        type: FieldTypes.User,
        isSortable: true,
    },
    [IdeaFieldNames.OwnerId]: {
        name: IdeaFieldNames.OwnerId,
        type: FieldTypes.User,
        isSortable: true,
    },
    [IdeaFieldNames.PotentialEstimate]: {
        name: IdeaFieldNames.PotentialEstimate,
        type: FieldTypes.Single,
        values: [EstimatesValue.VERY_LOW, EstimatesValue.LOW, EstimatesValue.MEDIUM, EstimatesValue.HIGH, EstimatesValue.VERY_HIGH],
        isSortable: true,
    },
    [IdeaFieldNames.TimeEstimate]: {
        name: IdeaFieldNames.TimeEstimate,
        type: FieldTypes.Single,
        values: [EstimatesValue.VERY_LOW, EstimatesValue.LOW, EstimatesValue.MEDIUM, EstimatesValue.HIGH, EstimatesValue.VERY_HIGH],
        isSortable: true,
    },
    [IdeaFieldNames.EffortEstimate]: {
        name: IdeaFieldNames.EffortEstimate,
        type: FieldTypes.Single,
        values: [EstimatesValue.VERY_LOW, EstimatesValue.LOW, EstimatesValue.MEDIUM, EstimatesValue.HIGH, EstimatesValue.VERY_HIGH],
        isSortable: true,
    },
    [IdeaFieldNames.CreatedAt]: {
        name: IdeaFieldNames.CreatedAt,
        type: FieldTypes.Date,
        isSortable: true,
    },
    [IdeaFieldNames.LastModificationAt]: {
        name: IdeaFieldNames.LastModificationAt,
        type: FieldTypes.Date,
        isSortable: true,
    },
    [IdeaFieldNames.ReactionsCount]: {
        name: IdeaFieldNames.ReactionsCount,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [IdeaFieldNames.DiscardReason]: {
        name: IdeaFieldNames.DiscardReason,
        type: FieldTypes.Number,
        isSortable: true,
    },
    [IdeaFieldNames.DiscardStatement]: {
        name: IdeaFieldNames.DiscardStatement,
        type: FieldTypes.Text,
        isSortable: true,
    },
    [IdeaFieldNames.Status]: {
        name: IdeaFieldNames.Status,
        type: FieldTypes.Single,
        values: [IdeaStatus.OPEN, IdeaStatus.CONVERTED, IdeaStatus.DISCARDED],
        isSortable: true,
    },
} satisfies Partial<Record<string, FieldDefinition>>;

export function isFilterDefinition(expression: any): expression is FilterDefinition {
    const { success } = zFilterDefinition.safeParse(expression);
    return success;
}

export function isICompareExpression(expression: any): expression is ICompareExpression {
    const { success } = zCompareExpression.safeParse(expression);
    return success;
}

export const EMPTY_FILTER: FilterDefinition = [];

export type Scope = ScopeDto;

export const DefaultScopeStartDate = `${MinYear}-01-01`;
export const DefaultScopeEndDate = `${MaxYear}-12-31`;
export const DefaultScopeAttributes = {};

export const isEffectFilterCurrencyField = (field: string): boolean =>
    Object.values(EffectFilterCurrencyField).some((currencyField) => field === currencyField || field.endsWith(capitalize(currencyField)));
