import { z } from "zod";
import {
    HistoryEventType,
    Sort,
    SubTaskGroupBy,
    SubTaskGroupOrderBy,
    SubTaskOrderBy,
    SubTaskPriority,
    SubTaskStatus,
    SubTaskStatusFilter,
} from "../constants";
import { zDateString, zMaxInt, zMediumText, zNumericId, zShortText } from "./baseschemas";
import { zCommentDto } from "./comments";
import { zFlatMeasureDto } from "./measures";

export const zSubTaskHistoryDto = z.object({
    id: z.number().int(),
    parentId: z.number().int(),
    userId: z.number().int(),
    datetime: z.date(),
    type: z.nativeEnum(HistoryEventType),
    previousValue: z.string().nullable(),
    newValue: z.string().nullable(),
    attribute: z.string(),
});

export const zSubTaskHistoryListDto = z.array(zSubTaskHistoryDto);
export type SubTaskHistoryDto = z.infer<typeof zSubTaskHistoryDto>;
export type SubTaskHistoryListDto = z.infer<typeof zSubTaskHistoryListDto>;

export const zSubTaskDto = z.object({
    id: z.number().int(),
    order: z.number().int(),
    clientId: z.number().int().nullable(),
    status: z.nativeEnum(SubTaskStatus),
    duedate: zDateString.nullable(),
    assignedToId: z.number().int().nullable(),
    createdById: z.number().int(),
    createdAt: z.date(),
    updatedById: z.number().int(),
    updatedAt: z.date(),
    title: zShortText.nullable(),
    description: z.string().trim().nullable(),
    measureId: z.number().int(),
    remindAt: zDateString.nullable(),
    priority: z.nativeEnum(SubTaskPriority),
    gateTaskId: z.number().int().nullable(),
    effectivenessStart: zDateString.nullish(),
    effect: z.number().optional(),
    estimatedEffort: zMaxInt.nonnegative().nullable(),
    trackedTime: zMaxInt.nonnegative().nullable(),
    measure: zFlatMeasureDto,
    comments: z.array(zCommentDto).optional(),
});
export type SubTaskDto = z.infer<typeof zSubTaskDto>;

export const zSubTaskListDto = z.array(zSubTaskDto);

export type SubTaskListDto = z.infer<typeof zSubTaskListDto>;

export const zSubTaskFilterRequestQueryParams = z.object({
    status: z.coerce.number().pipe(z.nativeEnum(SubTaskStatusFilter)).optional(),
    orderBy: z.nativeEnum(SubTaskOrderBy).optional(),
    sort: z.nativeEnum(Sort).optional(),
    grouping: z.preprocess((val) => val === "true", z.boolean().optional()),
    groupBy: z.nativeEnum(SubTaskGroupBy).optional(),
    groupOrderBy: z.nativeEnum(SubTaskGroupOrderBy).optional(),
    groupSort: z.nativeEnum(Sort).optional(),
    assignedToIds: z.array(z.coerce.number().pipe(zNumericId.or(z.literal(0)))).optional(),
    createdByIds: z.array(z.coerce.number().pipe(zNumericId)).optional(),
    filterId: zNumericId.optional(),
    filterDefinition: z.string().optional(),
});

export const zSubTasksOrderedDto = z.object({
    tasks: z.array(zSubTaskDto),
    groups: z.array(z.array(z.number().int())).optional(),
});
export type SubTasksOrderedDto = z.infer<typeof zSubTasksOrderedDto>;

export const zUpdateSubTaskRequestBody = z.object({
    duedate: z.coerce.date().nullish(),
    title: zShortText.optional(),
    description: zMediumText.optional(),
    assignedToId: z.number().int().nullable().optional(),
    status: z.nativeEnum(SubTaskStatus).optional(),
    priority: z.nativeEnum(SubTaskPriority).optional(),
    remindAt: z.coerce.date().nullish(),
    gateTaskId: z.number().int().optional().nullable(),
    estimatedEffort: zMaxInt.nonnegative().nullish(),
    trackedTime: zMaxInt.nonnegative().nullish(),
});

export type UpdateSubTaskRequestBody = z.infer<typeof zUpdateSubTaskRequestBody>;

export const zUpdateSubTaskRequestParams = z.object({
    id: zNumericId,
});

export type UpdateSubTaskRequestParams = z.infer<typeof zUpdateSubTaskRequestParams>;

export const zGetSubTaskRequestParams = z.object({
    gateTaskId: zNumericId,
});

export const zCreateSubTaskRequestBody = z.object({
    title: z.string().trim(),
    measureId: z.number().int(),
    gateTaskId: z.number().int().optional(),
});

export type CreateSubTaskRequestBody = z.infer<typeof zCreateSubTaskRequestBody>;
export type SubTaskFilterRequestQueryParams = z.infer<typeof zSubTaskFilterRequestQueryParams>;
