/**
 * Checks if a value has valid format for a currency value in our DB.
 */
export function validateCurrencyValue(value: number | string): boolean {
    // add runtime check for use in JavaScript
    if (typeof value !== "number" && typeof value !== "string") {
        return false;
    }

    // empty values are okay
    if (value.toString().length === 0) {
        return true;
    }

    // strip minus from value
    const strValue = value.toString().replace("-", "");

    // Exponential numbers are too big
    if (strValue.includes("e")) {
        return false;
    }

    // check for decimal separator
    const parts = strValue.split(".");

    switch (parts.length) {
        case 1:
            if (parts[0].length <= 15) {
                return true;
            }
            break;
        case 2:
            if (parts[0].length <= 15 && parts[1].length <= 4) {
                return true;
            }
            break;
        default:
            break;
    }

    return false;
}
