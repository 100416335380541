import moment, { Moment } from "moment";

/**
 * This is the EXCLUSIVE year for date ranges in the app. (e.g. 31.12.2049)
 */
export const MaxYear = new Date().getFullYear() + 25;

/**
 * This is the INCLUSIVE year for date ranges in the app. (e.g. 1.1.2000)
 */
export const MinYear = 2000;

/**
 * The start date for the minimum date range.
 */
export const FirstDate = moment.utc().year(MinYear).startOf("year");

/**
 * The end date for the maximum date range.
 */
export const LastDate = moment
    .utc()
    .year(MaxYear - 1)
    .endOf("year");

/**
 * The boundarie checks for date ranges.
 * '[' or ']' includes the start and end date in an isBetween check.
 * '(' or ')' would exclude the start or end date.
 */
export const InclusivityBoth = "[]";

/**
 * Returns true if the given date is between the start and end date. Including the start and end date.
 */
export const isDateBetween = (date: Date | Moment | null, startDate = FirstDate, endDate = LastDate): boolean => {
    if (date === null) {
        return false;
    }
    if (!startDate.isValid() || !endDate.isValid()) {
        return false;
    }
    return moment(date).isBetween(startDate, endDate, "day", InclusivityBoth);
};
