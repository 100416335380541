export enum AttributeId {
    PRODUCT_GROUP_ID = 6,
    COST_LEVER_ID = 9,
    DISCARD_REASON = 15,
    DISCARD_STATEMENT = 16,
    CURRENT_SUPPLIER = 19,
    NEW_SUPPLIER = 20,
    DESCRIPTION = 22,
    PROJECT = 24,
    ORIGIN = 25,
    ORIGINATOR_CLIENT = 26,
}

export enum AttributeTitle {
    CompanyId = "companyId",
    CostLeverId = "costLeverId",
    CurrentSupplier = "currentSupplier",
    Description = "description",
    DiscardReason = "discardReason",
    DiscardStatement = "discardStatement",
    NewSupplier = "newSupplier",
    OriginatorClient = "originatorClient",
    ProductGroupId = "productGroupId",
    Project = "project",
    Departments = "department",
    GroupsWithAccess = "groupsWithAccess",
}

export enum AttributeTable {
    Companies = "companies",
    CostLevers = "cost-levers",
    CustomValues = "custom-values",
    TreeNodes = "tree-nodes",
    Projects = "projects",
    Suppliers = "suppliers",
    Users = "users",
    Departments = "departments",
    GroupsWithAccess = "groupsWithAccess",
}

export enum AttributeType {
    Combobox = "combobox",
    Text = "text",
    Tree = "tree",
}

export enum AttributeTarget {
    EffectCategory = "effect-category",
    Measure = "measure",
}
