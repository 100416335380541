import { z } from "zod";
import { FieldTypes } from "../constants";

const zFieldDefinition = z.object({
    name: z.string(),
    type: z.nativeEnum(FieldTypes),
    isSortable: z.boolean().optional(),
    attributeName: z.string().optional(),
    values: z.array(z.number()).or(z.array(z.string())).optional(),
    timerangeField: z.string().optional(),
    isHiddenFilter: z.boolean().optional(),
});

// Prefix with I to keep old interface name, because there is already a different type exported from api-shared
export type IFieldDefinition = z.infer<typeof zFieldDefinition>;

export const zFieldDefinitionsDto = z.record(zFieldDefinition);

export type FieldDefinitionsDto = z.infer<typeof zFieldDefinitionsDto>;
