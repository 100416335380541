import { z } from "zod";
import { AttributeTable, TranslationType } from "../../constants";
import { CustomFieldNameAllowedRegex } from "../../helper/sanitizer";
import { zNullishNumericIdQueryParam } from "../baseschemas";

const zAttributeOptionsValue = z.object({
    id: z.number(),
    name: z.string().optional(),
});

const zAttributeOptions = z.object({
    name: z.string().optional(),
    depends: z.string().optional(),
    dependsValue: z.number().optional(),
    values: z.array(zAttributeOptionsValue).optional(),
});

export const zSuperAdminAttributeDto = z.object({
    id: z.number(),
    title: z.string(),
    type: z.string(),
    isMulti: z.boolean().nullable(),
    isCreatable: z.boolean().nullable(),
    clientId: z.number().nullable(),
    tableName: z.nativeEnum(AttributeTable).nullable(),
    translate: z.nativeEnum(TranslationType).nullable(),
    options: zAttributeOptions.nullable(),
    isFilled: z.boolean(),
    isUsedInEffectCategory: z.boolean(),
});

export const zSuperAdminAttributeListDto = z.array(zSuperAdminAttributeDto);

export const zCreateSuperAdminCustomFieldDto = z.object({
    title: z.string().trim().min(1).regex(CustomFieldNameAllowedRegex),
    type: z.string().trim().min(1),
    isMulti: z.boolean().nullable(),
    isCreatable: z.boolean().nullable(),
    clientId: z.preprocess((x) => Number(x), z.number().int()),
    tableName: z.nativeEnum(AttributeTable).nullable(),
    translate: z.nativeEnum(TranslationType).nullable(),
});

export const zUpdateSuperAdminCustomFieldDto = z.object({
    type: z.string().trim().min(1),
    isMulti: z.boolean().nullable(),
    isCreatable: z.boolean().nullable(),
    tableName: z.nativeEnum(AttributeTable).nullable(),
    translate: z.nativeEnum(TranslationType).nullable(),
});

export const zSuperAdminGetCustomFieldsQuery = z.object({
    clientId: zNullishNumericIdQueryParam,
});

export type SuperAdminAttributeDto = z.infer<typeof zSuperAdminAttributeDto>;
export type SuperAdminAttributeListDto = z.infer<typeof zSuperAdminAttributeListDto>;
export type CreateSuperAdminCustomFieldDto = z.infer<typeof zCreateSuperAdminCustomFieldDto>;
export type UpdateSuperAdminCustomFieldDto = z.infer<typeof zUpdateSuperAdminCustomFieldDto>;
