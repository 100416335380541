import { z } from "zod";

export const zMinMaxString = z.string().trim().min(1).max(1024);

const MEDIUMTEXT_SIZE_IN_BYTES = 16777215;
const MAX_BYTES_PER_TEXT_CHARACTER = 4; // utf8mb4 up to 4 bytes per character
export const MEDIUMTEXT_MAX_CHARS = Math.floor(MEDIUMTEXT_SIZE_IN_BYTES / MAX_BYTES_PER_TEXT_CHARACTER);
export const zMediumText = z.string().trim().max(MEDIUMTEXT_MAX_CHARS);

export const SHORT_TEXT_MAX_CHARS = 255;
export const zShortText = z.string().trim().max(SHORT_TEXT_MAX_CHARS);
export const zMinMaxShortText = zShortText.min(1);

// Note: `coerce` is the more modern variant for this use case instead of `preprocess`
// https://github.com/colinhacks/zod#preprocess
export const zNumericId = z.coerce.number().int({}).positive();

const MAX_STORABLE_INTEGER = 2 ** 31 - 1;
export const zMaxInt = z.coerce.number().int().max(MAX_STORABLE_INTEGER);

// JavaScript does not have a native Date type (without associated time), so the database date fields will be returned as string
// Some *Bos transform the string into date, so the schema should accept both date and valid date strings (YYYY-MM-DD) as input
export const zDateString = z
    .date()
    .or(z.string().pipe(z.coerce.date()))
    .transform((date) => date.toJSON());

// This will fall back to `null` whenever a non-zNumericId is passed
export const zNullishNumericIdQueryParam = z.coerce.number(zNumericId.nullish()).nullish().catch(null);

// maximum length of widget descriptions
export const WIDGET_DESCRIPTION_MAX_LENGTH = 512;

export const zHexColor = z.string().regex(/^#[0-9a-fA-F]{6}$/);
