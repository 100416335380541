import { z } from "zod";
import {
    AggregationMethod,
    ChartLayout,
    GlobalCalculationIdentifier,
    IdeaXAxis,
    MaxYear,
    MinYear,
    PivotMetric,
    RollingForecastDataRepresentation,
    Sort,
    SubTaskOrderBy,
    SubTaskStatusFilter,
    WaterfallSign,
    WidgetType,
} from "../constants";
import { WIDGET_DESCRIPTION_MAX_LENGTH, zMinMaxShortText, zNumericId } from "./baseschemas";
import { CommentParentType } from "./comment-stream";
import { zFilterDefinition } from "./filters";
import { zIdeaFilterDefintion } from "./ideas";
import { LiveRunUpGranularity, zLiveRunUpMonth } from "./live-run-up";
import { zProjectProgressMonth } from "./project-progress";
import { zScopeDto } from "./scope";
import { zMultiFieldValueTargets, zSingleFieldValueTargets } from "./targets";

export const zProcessListWidgetConfig = z.object({
    scope: zScopeDto,
    filter: zFilterDefinition,
    orderBy: z.string().min(1),
    sort: z.nativeEnum(Sort),
    columns: z.string().min(1).array(),
});

export type ProcessListWidgetConfig = z.infer<typeof zProcessListWidgetConfig>;

export const zCompletedEffectsWidgetConfig = z.object({
    fiscalYear: z.number().int(),
    forecast: z.object({
        isActive: z.boolean(),
        probabilities: z.record(z.number().gte(0).lte(1)),
    }),
    group: z.string().optional(),
    target: z.number().optional(),
    filter: zFilterDefinition,
    showSums: z.boolean(),
    axisMinValue: z.number().nullable(),
    axisMaxValue: z.number().nullable(),
});

export const zCompletedEffectsRequestBody = z.object({
    fiscalYear: z.number().int(),
    forecast: z.object({
        isActive: z.boolean(),
        probabilities: z.record(z.number().gte(0).lte(1)),
    }),
    group: z.string().optional(),
    target: z.number().optional(),
    filter: zFilterDefinition,
});

export type CompletedEffectsRequestBody = z.infer<typeof zCompletedEffectsRequestBody>;
export type CompletedEffectsWidgetConfig = z.infer<typeof zCompletedEffectsWidgetConfig>;

export const zActivityListWidgetConfig = z.object({
    status: z.nativeEnum(SubTaskStatusFilter),
    orderBy: z.nativeEnum(SubTaskOrderBy),
    filter: zFilterDefinition,
    sort: z.nativeEnum(Sort),
    assignedToIds: z.number().array().optional(),
    createdByIds: z.number().array().optional(),
});

export type ActivityListWidgetConfig = z.infer<typeof zActivityListWidgetConfig>;

export const zCustomBarChartWidgetConfig = z.object({
    aggregation: z.nativeEnum(AggregationMethod),
    metric: z.nativeEnum(PivotMetric),
    firstPivotField: z.string().min(1),
    secondPivotField: z.string().min(1).nullable(),
    scope: zScopeDto,
    filter: zFilterDefinition,
    sort: z.nativeEnum(Sort),
    orderBy: z.string().min(1),
    barDisplayLayout: z.nativeEnum(ChartLayout),
    showSums: z.boolean(),
    axisMinPotentialValue: z.number().nullable(),
    axisMaxPotentialValue: z.number().nullable(),
    axisMinCountValue: z.number().nullable(),
    axisMaxCountValue: z.number().nullable(),
    targets: zMultiFieldValueTargets.nullable(),
    showRelativeRepresentation: z.boolean(),
    referenceValues: z
        .object({
            timestamp: z.string(),
            data: z.record(z.number()),
            config: z.object({
                aggregation: z.nativeEnum(AggregationMethod),
                firstPivotField: z.string().min(1),
                filter: zFilterDefinition,
                scope: zScopeDto,
            }),
        })
        .nullable(),
    showReferenceValues: z.boolean(),
    defaultStartTreeNodeIds: z.record(z.string(), zNumericId.nullable()).nullable(),
});

export type CustomBarChartWidgetConfig = z.infer<typeof zCustomBarChartWidgetConfig>;

export const zProcessWhiteSpotMatrixWidgetConfig = z.object({
    aggregation: z.nativeEnum(AggregationMethod),
    metric: z.nativeEnum(PivotMetric),
    rowPivotField: z.string().min(1).nullable(),
    columnPivotField: z.string().min(1).nullable(),
    scope: zScopeDto,
    filter: zFilterDefinition,
    sortRow: z.nativeEnum(Sort),
    orderByRow: z.string().min(1),
    sortColumn: z.nativeEnum(Sort),
    orderByColumn: z.string().min(1),
    useManualMaxValues: z.boolean(),
    maxDisplayPotential: z.number(),
    maxDisplayCount: z.number().int(),
    defaultStartTreeNodeIds: z.record(z.string(), zNumericId.nullable()).nullable(),
});

export type ProcessWhiteSpotMatrixWidgetConfig = z.infer<typeof zProcessWhiteSpotMatrixWidgetConfig>;

export const zFunnelChartWidgetConfig = z.object({
    filter: zFilterDefinition,
    scope: zScopeDto,
});

export type FunnelChartWidgetConfig = z.infer<typeof zFunnelChartWidgetConfig>;

export const zRollingForecastWidgetConfig = z.object({
    filter: zFilterDefinition,
    scope: zScopeDto.extend({ startDate: z.string(), endDate: z.string() }),
    calculationDisplayModes: z.record(z.nativeEnum(GlobalCalculationIdentifier), z.nativeEnum(RollingForecastDataRepresentation)),
    targetValues: z.record(z.number()),
    targetPresentation: z.nativeEnum(RollingForecastDataRepresentation),
    showCombinedACT: z.boolean(),
    showSums: z.boolean(),
    axisMinValue: z.number().nullable(),
    axisMaxValue: z.number().nullable(),
});

export type RollingForecastWidgetConfig = z.infer<typeof zRollingForecastWidgetConfig>;

export const zCommentStreamWidgetConfig = z.object({
    entities: z.array(z.nativeEnum(CommentParentType)),
    commentedBy: z.array(z.number()),
    responsiblePerson: z.array(z.number()),
});

export type CommentStreamWidgetConfig = z.infer<typeof zCommentStreamWidgetConfig>;

export const zBasicMeasureFilterAndScopeConfig = z.object({
    scope: zScopeDto,
    filter: zFilterDefinition,
});

export type BasicMeasureFilterAndScopeConfig = z.infer<typeof zBasicMeasureFilterAndScopeConfig>;

export type DashboardLayout = Array<{ widgetId: number; location: WidgetLocation }>;

export const zIdeaListWidgetConfig = z.object({
    filter: zIdeaFilterDefintion,
    orderBy: z.string().min(1),
    sort: z.nativeEnum(Sort),
    columns: z.string().min(1).array(),
});

export type IdeaListWidgetConfig = z.infer<typeof zIdeaListWidgetConfig>;

export const zIdeaMatrixWidgetConfig = z.object({
    filter: zIdeaFilterDefintion,
    xAxis: z.nativeEnum(IdeaXAxis),
});

export type IdeaMatrixWidgetConfig = z.infer<typeof zIdeaMatrixWidgetConfig>;

export const zLiveRunUpWidgetConfig = z.object({
    filter: zFilterDefinition,
    start: zLiveRunUpMonth,
    end: zLiveRunUpMonth,
    xAxisGranularity: z.nativeEnum(LiveRunUpGranularity),
    accumulationStart: zLiveRunUpMonth.nullable(),
    target: z.number().nullable(),
    showSums: z.boolean(),
    axisMinValue: z.number().nullable(),
    axisMaxValue: z.number().nullable(),
    showReferenceValues: z.boolean(),
    referenceValues: z
        .object({
            timestamp: z.string(),
            data: z.record(z.number()),
            config: z.object({
                filter: zFilterDefinition,
                start: zLiveRunUpMonth,
                end: zLiveRunUpMonth,
                xAxisGranularity: z.nativeEnum(LiveRunUpGranularity),
                accumulationStart: zLiveRunUpMonth.nullable(),
            }),
        })
        .nullable(),
});

export type LiveRunUpWidgetConfig = z.infer<typeof zLiveRunUpWidgetConfig>;

export const zProjectProgressWidgetConfig = z.object({
    filter: zFilterDefinition,
    start: zProjectProgressMonth,
    end: zProjectProgressMonth,
    scope: zScopeDto.omit({ startDate: true, endDate: true }),
    showSums: z.boolean(),
    axisMinValue: z.number().nullable(),
    axisMaxValue: z.number().nullable(),
    showReferenceValues: z.boolean(),
    referenceValues: z
        .object({
            timestamp: z.string(),
            data: z.record(z.number()),
            config: z.object({
                filter: zFilterDefinition,
                start: zProjectProgressMonth,
                end: zProjectProgressMonth,
                scope: zScopeDto.omit({ startDate: true, endDate: true }),
                recurringAttribute: z.string().nullable(),
                recurringFieldValues: z.array(z.union([z.string(), z.number()])),
                oneTimeFieldValues: z.array(z.union([z.string(), z.number()])),
            }),
        })
        .nullable(),
    recurringAttribute: z.string().nullable(),
    recurringFieldValues: z.array(z.union([z.string(), z.number()])),
    oneTimeFieldValues: z.array(z.union([z.string(), z.number()])),
    targets: zSingleFieldValueTargets.nullable(),
});

export type ProjectProgressWidgetConfig = z.infer<typeof zProjectProgressWidgetConfig>;
export const zTimelineWidgetConfig = z.object({
    target: z.number().nullable(),
    axisMinValue: z.number().nullable(),
    axisMaxValue: z.number().nullable(),
    fiscalYear: z.number().int(),
    timeFrame: z.number().int(),
    curveFormulas: z.record(z.string()),
    filter: zFilterDefinition,
});

export type TimelineWidgetConfig = z.infer<typeof zTimelineWidgetConfig>;

export const zWeeklySavingsRunUpWidgetConfig = z.object({
    filter: zFilterDefinition,
    year: z.number().int().min(MinYear).max(MaxYear),
    target: z.number().nullable(),
    showSums: z.boolean(),
    referenceDate: z.string().optional(),
});
export type WeeklySavingsRunUpWidgetConfig = z.infer<typeof zWeeklySavingsRunUpWidgetConfig>;

export const zWaterfallBlockData = z.object({
    name: z.string(),
    sign: z.nativeEnum(WaterfallSign),
    fieldValues: z.array(z.union([z.string(), z.number()])),
    resultName: z.string(),
});

export type WaterfallBlockData = z.infer<typeof zWaterfallBlockData>;

export const zWaterfallWidgetConfig = z.object({
    scope: zScopeDto,
    filter: zFilterDefinition,
    showSums: z.boolean(),
    axisMinValue: z.number().nullable(),
    axisMaxValue: z.number().nullable(),
    attribute: z.string().nullable(),
    fieldValues: z.array(z.union([z.string(), z.number()])),
    blocks: z.array(zWaterfallBlockData),
    initialBlockName: z.string(),
});

export type WaterfallWidgetConfig = z.infer<typeof zWaterfallWidgetConfig>;

export const zWidgetConfig = z.union([
    zProcessListWidgetConfig,
    zCompletedEffectsWidgetConfig,
    zActivityListWidgetConfig,
    zCustomBarChartWidgetConfig,
    zRollingForecastWidgetConfig,
    zIdeaListWidgetConfig,
    zIdeaMatrixWidgetConfig,
    zFunnelChartWidgetConfig,
    zProcessWhiteSpotMatrixWidgetConfig,
    zCommentStreamWidgetConfig,
    zLiveRunUpWidgetConfig,
    zBasicMeasureFilterAndScopeConfig,
    zProjectProgressWidgetConfig,
    zTimelineWidgetConfig,
    zWeeklySavingsRunUpWidgetConfig,
    zWaterfallWidgetConfig,
]);

export type WidgetConfig = z.infer<typeof zWidgetConfig>;

const zWidgetLocation = z.object({
    x: z.number(),
    y: z.number(),
    w: z.number(),
    h: z.number(),
});

export type WidgetLocation = z.infer<typeof zWidgetLocation>;

const zWidgetBase = z.object({
    id: zNumericId,
    name: z.string(),
    description: z.string().nullable(),
    dashboardId: zNumericId,
    location: zWidgetLocation,
    updatedAt: z.date(),
});

export const zWidgetDto = z.intersection(
    zWidgetBase,
    z.discriminatedUnion("type", [
        z.object({
            type: z.literal(WidgetType.ProcessList),
            config: zProcessListWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.CompletedEffects),
            config: zCompletedEffectsWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.ActivityList),
            config: zActivityListWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.CustomBarChart),
            config: zCustomBarChartWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.FunnelChart),
            config: zFunnelChartWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.RollingForecast),
            config: zRollingForecastWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.IdeaList),
            config: zIdeaListWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.IdeaMatrix),
            config: zIdeaMatrixWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.ProcessWhiteSpotMatrix),
            config: zProcessWhiteSpotMatrixWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.CommentStream),
            config: zCommentStreamWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.LiveRunUp),
            config: zLiveRunUpWidgetConfig,
        }),
        z.object({
            type: z.literal(WidgetType.PotentialKPI),
            config: zBasicMeasureFilterAndScopeConfig,
        }),
        z.object({
            type: z.literal(WidgetType.StatusAggregation),
            config: zBasicMeasureFilterAndScopeConfig,
        }),
        z.object({
            type: z.literal(WidgetType.ProjectProgress),
            config: zProjectProgressWidgetConfig,
        }),
        z.object({ type: z.literal(WidgetType.Timeline), config: zTimelineWidgetConfig }),
        z.object({ type: z.literal(WidgetType.WeeklySavingsRunUp), config: zWeeklySavingsRunUpWidgetConfig }),
        z.object({ type: z.literal(WidgetType.Waterfall), config: zWaterfallWidgetConfig }),
    ]),
);

export type WidgetDto = z.infer<typeof zWidgetDto>;

export const zWidgetListDto = z.array(zWidgetDto);

export type WidgetListDto = z.infer<typeof zWidgetListDto>;

export const zWidgetTemplateDto = z.object({
    id: zNumericId,
    name: z.string(),
    description: z.string(),
    type: z.nativeEnum(WidgetType),
    // config may contain arbitrary placeholders, so cannot be typed any further
    config: z.record(z.unknown()),
    clientId: zNumericId.nullable(),
});

export type WidgetTemplateDto = z.infer<typeof zWidgetTemplateDto>;

export const zGetWidgetTemplatesResponseDto = z.array(zWidgetTemplateDto);

export type GetWidgetTemplatesResponseDto = z.infer<typeof zGetWidgetTemplatesResponseDto>;

export const zUpdateWidgetRequestBodyDto = z.object({
    name: zMinMaxShortText.optional(),
    description: z.string().max(WIDGET_DESCRIPTION_MAX_LENGTH).trim().nullable().optional(),

    // Validating the widget configs with zod is complicated. It will only work with a discriminated union otherwise zod will remove values when using a normal union
    // Explicit validation of the config is done on service level
    config: z.record(z.unknown()).optional(),
});

export const zUpdateWidgetRequestParamsDto = z.object({
    dashboardId: zNumericId,
    widgetId: zNumericId,
});

export const zCreateWidgetRequestBodyDto = z.object({
    name: zMinMaxShortText,
    description: z.string().max(WIDGET_DESCRIPTION_MAX_LENGTH).trim().nullable(),
    // config may contain arbitrary placeholders, so cannot be typed any further
    config: z.record(z.unknown()),
    type: z.nativeEnum(WidgetType),
});

export const zCreateWidgetRequestParamsDto = z.object({
    dashboardId: zNumericId,
});

export type UpdateWidgetRequestBodyDto = z.infer<typeof zUpdateWidgetRequestBodyDto>;
export type UpdateWidgetRequestParamsDto = z.infer<typeof zUpdateWidgetRequestParamsDto>;
export type CreateWidgetRequestBodyDto = z.infer<typeof zCreateWidgetRequestBodyDto>;
export type CreateWidgetRequestParamsDto = z.infer<typeof zCreateWidgetRequestParamsDto>;
