import { z } from "zod";

// Contains only persisted options, client uses some more options additionally, e.g. function for "name"
export const zAttributeOptions = z
    .object({
        name: z.string(),
        depends: z.string(),
        dependsValue: z.number(),
        values: z.array(
            z.object({
                id: z.number(),
                // name property is not used in persisted options, but some generated attributes use the name property
                name: z.string().optional(),
            }),
        ),
        filteredValues: z.array(z.number()),
        resolveDuplicates: z.boolean(),
    })
    .partial();

export type AttributeOptions = z.infer<typeof zAttributeOptions>;
